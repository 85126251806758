import React from 'react'
import { theme as sharedTheme } from '@webnuts/shared-components/build/projects/university-greenland/theme'
import deepmerge from 'deepmerge'
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro'

/** Merge the global theme and the local theme together. */
export const theme = deepmerge(sharedTheme, {
  /** The max width of everything in the app. */
  maxWidth: '948px'
} as const)

type Props = Omit<React.ComponentProps<typeof StyledThemeProvider>, 'theme'>

/** A helper for providing the correct <ThemeProvider /> in the app. */
export const ThemeProvider: React.FC<Props> = ({ children, ...props }) => (
  <StyledThemeProvider {...props} theme={theme}>
    <>{children}</>
  </StyledThemeProvider>
)
