import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useService, Service } from './useService'

/**
 * Returns a ref that tells whether or not the current component has been
 * unmounted yet.
 */
export const useIsMounted = () => {
  const isMounted = React.useRef(true)
  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

type ServiceHookConfig<Args, Result> = {
  resolver: (hookArgs: Args, execute: Service) => Promise<Result>
}

export type ServiceStateView<Result> =
  | { status: 'initial' }
  | { status: 'loading' }
  | { status: 'error'; error: Error }
  | { status: 'ok'; result: Result }

// Helper type to get the type of a promise's 'then' argument
type ThenArg<T> = T extends PromiseLike<infer U> ? U : T
type ResolverServiceState<R extends (...args: any) => Promise<any>> = ServiceStateView<ThenArg<ReturnType<R>>>

// Factory that can produce a view hook that interacts with a service.
// Pass a resolver function that resolves the request with the service.
type ViewServiceHook<Args, Result> = (initialQuery: Args) => [ServiceStateView<Result>, (query: Args) => void]
type ViewServiceHookFactory = <Args, Result>(config: ServiceHookConfig<Args, Result>) => ViewServiceHook<Args, Result>

export const createServiceViewHook: ViewServiceHookFactory = ({ resolver }) => (initialQuery) => {
  const isMountedRef = useIsMounted()
  const [state, setState] = useState<ResolverServiceState<typeof resolver>>({ status: 'initial' })
  const _initialQuery = useRef(initialQuery)
  const service = useService()

  const mountedSetState: typeof setState = React.useCallback(
    (...args) => {
      if (isMountedRef.current) {
        setState(...args)
      }
    },
    [setState, isMountedRef]
  )

  const refresh = useCallback(
    async (query) => {
      mountedSetState({ status: 'loading' })
      try {
        const result = await resolver(query, service)
        // TODO: When we're done with mocks, simplify this case.
        if (
          typeof result === 'object' &&
          (result as any).hasOwnProperty('status') &&
          typeof (result as any).status === 'string'
        ) {
          mountedSetState(result as any)
        } else {
          mountedSetState({ status: 'ok', result })
        }
      } catch (error) {
        mountedSetState({ status: 'error', error })
      }
    },
    [mountedSetState, service]
  )

  // perform a refresh on mount
  useEffect(() => {
    refresh(_initialQuery.current)
  }, [refresh, _initialQuery])

  return [state, refresh]
}

export type ServiceStateCommand<Result> =
  | ServiceStateView<Result>
  | { status: 'invalid'; invalidations: ReadonlyArray<any> }

// Factory that can produce a command hook that interacts with a service.
// Pass a resolver function that resolves the request with the service.
type CommandServiceHook<Args, Result> = () => [
  ServiceStateCommand<Result>,
  (args: Args) => Promise<ServiceStateCommand<Result>>
]
type CommandServiceHookFactory = <Args, Result>(
  config: ServiceHookConfig<Args, Result>
) => CommandServiceHook<Args, Result>

export const createServiceCommandHook: CommandServiceHookFactory = ({ resolver }) => () => {
  const [state, setState] = useState<ResolverServiceState<typeof resolver>>({ status: 'initial' })
  const service = useService()
  const isMountedRef = useIsMounted()

  const mountedSetState: typeof setState = React.useCallback(
    (...args) => {
      if (isMountedRef.current) {
        setState(...args)
      }
    },
    [setState, isMountedRef]
  )

  const emit = useCallback(
    async (args: any): Promise<ResolverServiceState<typeof resolver>> => {
      mountedSetState({ status: 'loading' })
      let result

      try {
        result = await resolver(args, service)
        // TODO: When we're done with mocks, simplify this case.
        if (
          typeof result === 'object' &&
          (result as any).hasOwnProperty('status') &&
          typeof (result as any).status === 'string'
        ) {
        } else {
          result = { status: 'ok', result }
        }
      } catch (error) {
        result = { status: 'error', error }
      }

      mountedSetState(result as any)
      return result as any
    },
    [mountedSetState, service]
  )

  return [state, emit]
}
