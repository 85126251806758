import React, { useState, useMemo, createContext, useContext, SetStateAction, Dispatch } from 'react'

type LocaleView = { [key: string]: string }

export type Locale = 'da' | 'en' | 'gl'
export const LocaleContext = createContext<[Locale, Dispatch<SetStateAction<Locale>>]>(['en', () => {}])
export const LocaleViewContext = createContext<[LocaleView, Dispatch<SetStateAction<LocaleView>>]>([{}, () => {}])
export const LocaleProvider: React.FC = ({ children }) => {
  const state = useState<Locale>('gl')
  return <LocaleContext.Provider value={state}>{children}</LocaleContext.Provider>
}

export const LocaleViewProvider: React.FC = ({ children }) => {
  const state = useState<LocaleView>({})
  return <LocaleViewContext.Provider value={state}>{children}</LocaleViewContext.Provider>
}

type Selector<T> = (translation: LocaleView) => T
type UseLocaleHook = () => [Locale, Dispatch<SetStateAction<Locale>>]

export const useLocale: UseLocaleHook = () => {
  const [locale, setLocale] = useContext(LocaleContext)
  return [locale, setLocale]
}

type UseLocaleViewHook = () => [LocaleView, Dispatch<SetStateAction<LocaleView>>]

export const useLocaleView: UseLocaleViewHook = () => {
  const [localeView, setLocaleView] = useContext(LocaleViewContext)
  return [localeView, setLocaleView]
}

type LocaleSelector = <T>(selector: Selector<T>, initialLocaleView?: LocaleView) => T

export const useLocaleSelector: LocaleSelector = (selector) => {
  const [localeView] = useLocaleView()

  const selection = useMemo(() => {
    return selector(localeView)
  }, [localeView, selector])

  return selection as ReturnType<typeof selector>
}
