import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// Missing arbitrary timezone support for Intl, in IE11.
import 'date-time-format-timezone'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))
