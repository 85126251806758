import React from 'react'
import { Router } from 'react-router-dom'
import { ThemeProvider } from '../theme'
import { LocaleProvider, LocaleViewProvider } from '../locale'

type Props = {
  history: React.ComponentProps<typeof Router>['history']
}

export const AppContext: React.FC<Props> = ({ history, children }) => (
  <Router history={history}>
    <ThemeProvider>
      <LocaleProvider>
        <LocaleViewProvider>{children}</LocaleViewProvider>
      </LocaleProvider>
    </ThemeProvider>
  </Router>
)
