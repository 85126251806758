import React from 'react'
import styled from 'styled-components/macro'

export const Page: React.FC = ({ children }) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
)

const Wrapper = styled.div`
  background: ${(p) => p.theme.colors.iceBlue[500]};
  min-height: 100vh;
  width: 100%;
`

const Content = styled.div`
  max-width: ${(p) => p.theme.maxWidth};
  margin: 0 auto;
  padding: 60px 5px;
`
