import { createServiceViewHook } from '../createServiceHook'

type Query = {
  locale: 'da' | 'en' | 'gl'
}

type Result = {
  [key: string]: string // todo: consider if we should refactor this to have an actual type (loads of duplicate code from the service is required)
}

export const useApplicantPageLocales = createServiceViewHook<Query, Result>({
  resolver: async ({ locale }, { listByType }) => {
    const { result } = await listByType(`locales/Applicant Page/${locale}`)

    return result.reduce(
      (keys: Result, localeKey: any) => ({
        ...keys,
        [localeKey.textKey]: localeKey.value
      }),
      {} as Result
    )
  }
})
