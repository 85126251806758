import React from 'react'
import axois from 'axios'
import { parse as cookieParse } from 'cookie'

import { Context, ScenariosByContext } from './types'

const apiUrl: string = process.env.REACT_APP_API_URL!

// Parses the current token from the document cookies
export const getToken = (): string | undefined => {
  const { session } = cookieParse(document.cookie)
  return typeof session === 'string' && session.length > 0 ? session : undefined
}

// axios instance for requesting from service
const service = axois.create({
  baseURL: apiUrl,
  timeout: 10000
})

// const toFormData = (Obj: { [key: string]: any }) => {
//   const formData = new FormData()
//   Object.keys(Obj).forEach((key: string) => formData.append(key, Obj[key]))
//   return formData
// }

service.interceptors.request.use((config) => {
  const token = getToken()

  // Add auth token if available
  if (token !== undefined) {
    Object.assign(config.headers, { Authorization: `Bearer ${token}` })
  }

  // TODO: Use this once we need form-data

  // Add content type if needed
  // if (config.url === '/scenario' && config.method === 'post') {
  //   Object.assign(config.data, { params: toFormData(config.data.params) })
  //   Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' })
  // }

  return config
})

type Invalidation = [Array<string | number>, string, string, string | undefined, string]
export type ServiceResponse<Result> = Promise<
  | {
      status: 'ok'
      result: Result
    }
  | {
      status: 'invalid'
      invalidations: Array<Invalidation>
    }
  | {
      status: 'error'
      error: Error
    }
>

// LOGGERS
const logScenario = (context: string, scenario: string, params: any) =>
  process.env.NODE_ENV === 'development' &&
  // eslint-disable-next-line no-console
  console.info('%c⬆︎', 'color: #ab47bc;', 'COMMAND', `${context}/${scenario}`, 'PARAMS:', { params })

const logView = (view: string, params: object) =>
  process.env.NODE_ENV === 'development' &&
  // eslint-disable-next-line no-console
  console.info('%c⬇︎', 'color: #4caf50;', 'VIEW:', view, 'params:', params)

const logScenarioError = (context: string, scenario: string, params: any, invalidations: Invalidation[]) =>
  process.env.NODE_ENV === 'development' &&
  // eslint-disable-next-line no-console
  console.error(`❌ SCENARIO '${context}/${scenario}'`, 'invalidations:', invalidations, 'params:', params)

const logViewError = (view: string, invalidations: Invalidation[]) =>
  process.env.NODE_ENV === 'development' &&
  // eslint-disable-next-line no-console
  console.error(`❌ VIEW '${view}'`, 'invalidations:', invalidations)

export const getInvalidationsFromError = (error: any): Invalidation[] => {
  return error.response.data
}

type SendScenario = <Ctx extends Context, Result>(
  context: Ctx,
  scenario: ScenariosByContext[Ctx],
  params: object
) => ServiceResponse<Result>
const _sendScenario: SendScenario = async (context, scenario, params) => {
  logScenario(context, scenario, params)
  try {
    const result = await service.post('/scenario', { context, scenario, params })
    return { status: 'ok', result: result.data }
  } catch (error) {
    const invalidations = getInvalidationsFromError(error)
    logScenarioError(context, scenario, params, invalidations)
    return invalidations.length > 0 ? { status: 'invalid', invalidations } : { status: 'error', error }
  }
}

type RequestView = <Result>(view: string, params: object) => ServiceResponse<Result>
const _requestView: RequestView = async (view, params) => {
  logView(view, params)
  try {
    const result = await service.post(view, params)
    return { status: 'ok', result: result.data }
  } catch (error) {
    const invalidations = getInvalidationsFromError(error)
    logViewError(view, invalidations)
    return invalidations.length > 0 ? { status: 'invalid', invalidations } : { status: 'error', error }
  }
}

// Hook that returns functions for communicating with the service
export const useService = () => {
  const sendCommand = React.useCallback(_sendScenario, [])
  const requestView = React.useCallback(_requestView, [])
  const listByType = React.useCallback(
    async (type: string) => {
      const response = await requestView(`/by-type`, { type })
      // Map response array to pick out values
      if (response.status === 'ok') {
        const val = { status: 'ok', result: (response.result as any).map((Obj: { key: any; value: any }) => Obj.value) }
        return val
      }

      return response
    },
    [requestView]
  )

  const getByKey = React.useCallback(
    async (key: string) => {
      const response = await requestView(`/by-key`, { key })
      if (response.status === 'ok') {
        return { status: 'ok', result: (response.result as any).value }
      }
      return response
    },
    [requestView]
  )

  // TODO: Remove these two later
  const getSubjectOptions: any = React.useCallback(() => requestView('subjectOptions', {}), [requestView])
  const getAcademicRegulationOptions: any = React.useCallback(async () => {
    const view = await listByType('academic-regulation')
    if (view.status === 'ok') {
      return (view as any).result.map((regulation: any) => ({
        value: regulation.key,
        label: regulation.title
      }))
    } else {
      return view
    }
  }, [listByType])

  // TODO remove this typecast (its wrong)
  return React.useMemo<{
    sendCommand: <Ctx extends Context>(context: Ctx, scenario: ScenariosByContext[Ctx], params: object) => Promise<any>
    listByType: (type: string) => Promise<any>
    getByKey: (key: string) => Promise<any>
    /**
     *  TODO: [subjectOptions] Remove later.
     * A hack for getting subject options for various views.
     */
    getSubjectOptions: () => Promise<any>
    /**
     *  TODO: Remove later.
     * A hack for getting academic regulation options for various views.
     */
    getAcademicRegulationOptions: () => Promise<{ label: string; value: string }[]>
  }>(() => ({ sendCommand, listByType, getByKey, getSubjectOptions, getAcademicRegulationOptions }), [
    sendCommand,
    listByType,
    getByKey,
    getSubjectOptions,
    getAcademicRegulationOptions
  ])
}

export type Service = ReturnType<typeof useService>
