import React from 'react'
import { Switch, Route } from 'react-router-dom'

const ApplicantPage = React.lazy(() => import('./pages/index'))
const NotFoundPage = React.lazy(() => import('./pages/404'))
const ServerErrorPage = React.lazy(() => import('./pages/500'))
const PreviewPage = React.lazy(() => import('./pages/preview'))

export const RootRouter: React.FC<{ children?: undefined }> = () => (
  <Switch>
    <Route path="/preview" exact component={PreviewPage} />
    <Route path="/500" exact component={ServerErrorPage} />
    <Route path="/:applicantAccessToken" exact component={ApplicantPage} />
    <Route component={NotFoundPage} />
  </Switch>
)
