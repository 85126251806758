import React, { useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { RootRouter } from './RootRouter'

import { GlobalStyle } from './components/GlobalStyle'
import { Page as PageWrapper } from './components/Page'
import { CenteredLoadingIndicator } from '@webnuts/shared-components/build/projects/university-greenland/CenteredLoadingIndicator'
import { AppContext } from './components/AppContext'
import { useLocaleView, useLocale } from './locale'
import { useApplicantPageLocales } from './service/views/useApplicantPageLocales'

const history = createBrowserHistory()

const Page: React.FC = () => {
  const [locale] = useLocale()
  const setLocaleView = useLocaleView()[1]
  const [texts, refreshTexts] = useApplicantPageLocales({ locale })

  useEffect(() => {
    if (texts.status === 'ok') {
      setLocaleView(texts.result)
    }
  }, [texts, setLocaleView])

  useEffect(
    () => {
      refreshTexts({ locale })
    },
    // running the effect whenever the refreshTexts is updates would cause an infinite loop
    // this might indicate an implementation problem in the hook, but we'll have to look at that on a later date
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )

  const isLoading = texts.status === 'loading' || texts.status === 'initial'

  if (isLoading) {
    return <CenteredLoadingIndicator />
  }

  return (
    <PageWrapper>
      <React.Suspense fallback={<CenteredLoadingIndicator />}>
        <RootRouter />
      </React.Suspense>
    </PageWrapper>
  )
}

const App: React.FC = () => (
  <>
    <GlobalStyle />
    <AppContext history={history}>
      <Page />
    </AppContext>
  </>
)

// eslint-disable-next-line import/no-default-export
export default App
